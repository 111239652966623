// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";

// Extend the base class
class Role extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Role";

  static instanceDefaults() {
    return {
      id: null,
      roleName: null,
      roleDescription: null,
      active: null,
      updatedAt: null
    };
  }
}

const servicePath = "roles";

const servicePlugin = makeServicePlugin({
  debug: process.env.NODE_ENV !== "production",
  Model: Role,
  service: feathersClient.service(servicePath),
  servicePath,
  state: {
    isAddingFilter: false,
    isRemovingFilter: false,
    filterRoles: []
  },
  mutations: {
    setIsAddingFilter: (state) => {
      state.isAddingFilter = true;
    },
    unsetIsAddingFilter: (state) => {
      state.isAddingFilter = false;
    },
    setIsRemovingFilter: (state) => {
      state.isRemovingFilter = true;
    },
    unsetIsRemovingFilter: (state) => {
      state.isRemovingFilter = false;
    },
    setFilterRoles: (state, payload) => {
      state.filterRoles = [];
      state.filterRoles = payload;
    },
    setClearAllFilters: (state) => {
      state.filterRoles = [];
    }
  },
  actions: {
    filterRoles: (context, payload) => {
      context.commit("setIsAddingFilter");
      context.commit("setFilterRoles", payload);
      context.commit("unsetIsAddingFilter");
    },
    clearAllFilters: (context) => {
      context.commit("setClearAllFilters");
    }

    // static setupInstance(data) {
    //   console.log("Inside setupInstance: ", data);
    // }
  }
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
