// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";

// Extend the base class
class Permit extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Permit";
  static instanceDefaults() {
    return {
      form_id: null,
      status: null,
      assigned_to: null,
      assigned_to_id: null,
      version: null,
      client_created_at: null,
      created_at: null,
      created_by: null,
      created_by_id: null,
      created_duration: null,
      created_location: null,
      client_updated_at: null,
      updated_at: null,
      updated_by: null,
      updated_by_id: null,
      updated_duration: null,
      updated_location: null,
      edited_duration: null,
      latitude: null,
      longitude: null,
      altitude: null,
      horizontal_accuracy: null,
      vertical_accuracy: null,
      speed: null,
      course: null,
      orig_fulcrum_id: null,
      new_fulcrum_id: null,
      atr_fulcrum_id: null,
      calc_title: null,
      permit_id: null,
      permit_desc: null,
      permit_type: null,
      permit_status: null,
      comm_type: null,
      gen_comment: null,
      asbuilt_plan: null,
      asbuilt_date: null,
      asbuilt_link: null,
      cabinet: null,
      cabinet_mount: null,
      meter: null,
      meter_mount: null,
      county_state: null,
      district: null,
      locale: null,
      loc_design: null,
      loc_design_other: null,
      special_design: null,
      special_design_other: null,
      last_const_date: null,
      last_inv_date: null,
      last_update_date: null,
      last_photo_date: null,
      last_quad_date: null,
      last_util_date: null,
      last_maint_date: null,
      last_sig_head_date: null,
      last_cabinet_date: null,
      last_pole_date: null
    };
  }

  static setupInstance(data) {
    // convert the comma separated string to an array
    // and then use the first element of the array
    if (data.permit_type !== null) {
      data.permit_type = data.permit_type.split(",");
    }
    // if (data.permit_type !== null) {
    //   data.permit_type = data.permit_type.split(",")[0];
    // }
    if (data.permit_desc === null) {
      data.permit_desc = "Description not available";
    }
  }
}

const servicePath = "permits";

const servicePlugin = makeServicePlugin({
  debug: process.env.NODE_ENV !== "production",
  Model: Permit,
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath,
  extend: () => {
    return {
      state: {
        pageLimit: 25,
        //pageSkip: 0,
        filterText: null,
        isAddingFilter: false,
        isRemovingFilter: false,
        isFilteringText: false
      },
      mutations: {
        setIsAddingFilter: (state) => {
          state.isAddingFilter = true;
        },
        unsetIsAddingFilter: (state) => {
          state.isAddingFilter = false;
        },
        setIsRemovingFilter: (state) => {
          state.isRemovingFilter = true;
        },
        unsetIsRemovingFilter: (state) => {
          state.isRemovingFilter = false;
        },
        setFilterTextEnter: (state) => {
          state.isFilteringText = true;
        },
        unsetFilterTextEnter: (state) => {
          state.isFilteringText = false;
        },
        setFilterText: (state, payload) => {
          state.filterText = null;
          state.filterText = payload;
        },
        setClearAllFilters: (state) => {
          state.filterText = null;
        }
      },
      actions: {
        filterText: (context, payload) => {
          context.commit("setFilterTextEnter");
          context.commit("setFilterText", payload);
          context.commit("unsetFilterTextEnter");
        },
        clearAllFilters: (context) => {
          context.commit("setClearAllFilters");
        }
      }
    };
  }
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
