// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";

// Extend the base class
class Profile extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Profile";

  static instanceDefaults() {
    return {
      userId: null,
      firstName: null,
      lastName: null,
      avatar: `${process.env.VUE_APP_ASSETS_URL}/avatars/no-avatar.jpg`,
      trafficOnly: false,
      constructionOnly: false,
      isOnline: false,
      isVisible: true,
      isNotifiedForNewIdr: false,
      isSentDailySummary: false,
      isNotifiedOnStatementUpdate: true,
      isNotifiedOnStatementComment: true,
      isNotifiedOnSubmitToDesignMgr: true,
      isNotifiedOnDesignMgrReject: true,
      isNotifiedOnPermitUpdate: true,
      isNotifiedOnPermitComment: true,
      isNotifiedOnReadyForReview: true,
      isNotifiedOnDesignMgrItemFlag: true,
      isNotifiedOnStatementApproverItemFlag: true,
      isNotifiedOnConstructionMgrItemFlag: true,
      isNotifiedOnConstructionApproverItemFlag: true
    };
  }
}

const servicePath = "profiles";

const servicePlugin = makeServicePlugin({
  debug: process.env.NODE_ENV !== "production",
  Model: Profile,
  idField: "id",
  service: feathersClient.service(servicePath),
  servicePath,
  enableEvents: true,
  handleEvents: {}
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
