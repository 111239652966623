// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";

// Extend the base class
class Organization extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Organization";

  static instanceDefaults() {
    return {
      form_id: null,
      status: null,
      assigned_to: null,
      assigned_to_id: null,
      version: null,
      client_created_at: null,
      created_at: null,
      created_by: null,
      created_by_id: null,
      created_duration: null,
      created_location: null,
      client_updated_at: null,
      updated_at: null,
      updated_by: null,
      updated_by_id: null,
      updated_duration: null,
      updated_location: null,
      edited_duration: null,
      calc_title: null,
      record_id: null,
      short_name: null,
      full_name: null,
      org_type: null,
      service_provided: null,
      utility_loc_client: null,
      comment: null
    };
  }
}

const servicePath = "organizations";

const servicePlugin = makeServicePlugin({
  debug: process.env.NODE_ENV !== "production",
  Model: Organization,
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath,
  state: {
    isAddingFilter: false,
    isRemovingFilter: false,
    filterOrganizations: []
  },
  mutations: {
    setIsAddingFilter: (state) => {
      state.isAddingFilter = true;
    },
    unsetIsAddingFilter: (state) => {
      state.isAddingFilter = false;
    },
    setIsRemovingFilter: (state) => {
      state.isRemovingFilter = true;
    },
    unsetIsRemovingFilter: (state) => {
      state.isRemovingFilter = false;
    },
    setFilterOrganizations: (state, payload) => {
      state.filterOrganizations = [];
      state.filterOrganizations = payload;
    },
    setClearAllFilters: (state) => {
      state.filterOrganizations = [];
    }
  },
  actions: {
    filterOrganizations: (context, payload) => {
      context.commit("setIsAddingFilter");
      context.commit("setFilterOrganizations", payload);
      context.commit("unsetIsAddingFilter");
    },
    clearAllFilters: (context) => {
      context.commit("setClearAllFilters");
    }

    // static setupInstance(data) {
    //   console.log("Inside setupInstance: ", data);
    // }
  }
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [
      // (context) => {
      //   let data = context.result.data;
      //   data.forEach(function (data) {
      //     if (data.service_provided !== null) {
      //       data.service_provided = data.service_provided.split(",");
      //     }
      //   });
      //   return context;
      // }
    ],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
