// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "@/feathers";

// Extend the base class
class RefreshToken extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "RefreshToken";

  static instanceDefaults() {
    return {};
  }
}

const servicePath = "refresh-tokens";

const servicePlugin = makeServicePlugin({
  Model: RefreshToken,
  service: feathersClient.service(servicePath),
  servicePath,

  // Our Custom Configuration
  idField: "id",
  nameStyle: "short",
  debug: process.env.NODE_ENV !== "production"
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
