// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";

// Extend the base class
class EstimateItem extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "EstimateItem";

  static instanceDefaults() {
    return {
      form_id: null,
      status: "Short List",
      assigned_to: null,
      assigned_to_id: null,
      version: null,
      client_created_at: null,
      created_at: null,
      created_by: null,
      created_by_id: null,
      created_duration: null,
      created_location: null,
      client_updated_at: null,
      updated_at: null,
      updated_by: null,
      updated_by_id: null,
      updated_duration: null,
      updated_location: null,
      edited_duration: null,
      old_item_id: null,
      old_permit_id: null,
      old_contract_id: null,
      old_proceed_num: null,
      old_ts_num: null,
      project_id: null,
      const_id: null,
      item_record: null,
      item_num: null,
      item_desc: null,
      item_unit: null,
      negotiable: null,
      barcode: null,
      item_cost: null,
      item_qty: null,
      item_comment: null,
      project_fulcrum_id: null
    };
  }

  // Computed Properties
  // get shortListName() {
  //   return `${this.contract_number} ${this.list_year}`;
  // }

  // static setupInstance(data) {
  // }
}

const servicePath = "estimate-items";

const servicePlugin = makeServicePlugin({
  debug: process.env.NODE_ENV !== "production",
  Model: EstimateItem,
  idField: "fulcrum_id",
  tempIdField: "__id",
  service: feathersClient.service(servicePath),
  servicePath,
  enableEvents: true,
  extend: () => {
    return {
      state: {
        pageSkip: 0,
        pageLimit: 25,
        isEnteringText: false,
        filterText: null,
        selectedItemsList: [],
        fulcrumRemovalItemsList: [],
        loading: false
      },
      getters: {
        getLoading: (state) => {
          return state.loading;
        },
        getSelectedItemsList: (state) => {
          return state.selectedItemsList;
        },
        getFulcrumRemovalItemsList: (state) => {
          return state.fulcrumRemovalItemsList;
        }
      },
      mutations: {
        setFilterTextEnter: (state) => {
          state.isEnteringText = true;
        },
        unsetFilterTextEnter: (state) => {
          state.isEnteringText = false;
        },
        setFilterText: (state, payload) => {
          state.filterText = null;
          state.filterText = payload;
          console.log(state.filterText);
        },
        setUpdatePageLimit: (state, payload) => {
          state.pageLimit = null;
          state.pageLimit = payload;
          console.log(state.pageLimit);
        },
        setUpdateSelectedItemsList: (state, payload) => {
          state.selectedItemsList = [];
          state.selectedItemsList = payload;
        },
        setUpdateFulcrumRemovalItemsList: (state, payload) => {
          state.fulcrumRemovalItemsList = [];
          state.fulcrumRemovalItemsList = payload;
        },
        setLoading: (state, payload) => {
          state.loading = payload;
        }
      },
      actions: {
        filterText: (context, payload) => {
          context.commit("setFilterTextEnter");
          context.commit("setFilterText", payload);
          context.commit("unsetFilterTextEnter");
        },
        updatePageLimit: (context, payload) => {
          context.commit("setUpdatePageLimit", payload);
        },
        updateSelectedItemsList: (context, payload) => {
          context.commit("setUpdateSelectedItemsList", payload);
        },
        updateFulcrumRemovalItemsList: (context, payload) => {
          context.commit("setUpdateFulcrumRemovalItemsList", payload);
        },
        updateLoading: (context, payload) => {
          context.commit("setLoading", payload);
        }
      },
      handleEvents: {
        created: (item) => {
          console.log("Greetings from the created event!");
          console.log(`${item} created in database`);
        },
        patched: (item) => {
          console.log("Greetings from the patched event!");
          console.log(`${item} patched in database`);
        },
        updated: (item) => {
          console.log("Greetings from the updated event!");
          console.log(`${item} updated in database`);
        },
        removed: (item, { model }) => {
          console.log(`${item} removed from database`);
          console.log(model);
        }
      }
    };
  }
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
