// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";
import hideTestData from "../hooks/hide-test-data";

// Extend the base class
class DailyLog extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "DailyLog";
  static instanceDefaults() {
    return {
      fulcrum_id: null,
      form_id: null,
      status: null,
      assigned_to: null,
      assigned_to_id: null,
      version: null,
      client_created_at: null,
      created_at: null,
      created_by: null,
      created_by_id: null,
      created_duration: null,
      created_location: null,
      client_updated_at: null,
      updated_at: null,
      updated_by: null,
      updated_by_id: null,
      updated_duration: null,
      updated_location: null,
      edited_duration: null,
      old_log_id: null,
      old_log_type: null,
      old_permit_id: null,
      old_project_id: null,
      invoiced: null,
      calc_title: null,
      const_id: null,
      project_id: null,
      contractor_list_id: null,
      inspector_first_name: null,
      inspector_last_name: null,
      const_type: null,
      inspector_record: null,
      idr_date: null,
      start_time: null,
      end_time: null,
      weather: null,
      temperature: null,
      wthr_comment: null,
      work_delay: null,
      delay_duration: null,
      delay_reason: null,
      delay_reason_other: null,
      comment_category: null,
      doc_link: null,
      idr_comment: null,
      idr_photo: null,
      idr_photo_caption: null,
      site_record: null,
      project_fulcrum_id: null
    };
  }

  static setupInstance(data) {
    // handle null weather data
    if (!data.weather || data.weather === null) {
      data.weather = "---";
    }

    // turn `idr_photo` into an array
    if (data.idr_photo && data.idr_photo !== null) {
      data.idr_photo = data.idr_photo.split(",");
    }

    // turn `idr_photo_caption` into an array
    if (data.idr_photo_caption && data.idr_photo_caption !== null) {
      data.idr_photo_caption = data.idr_photo_caption.split(",");
    }

    // combine first and last name of inspector
    if (data.inspector_first_name && data.inspector_last_name) {
      data.inspector_full_name = `${data.inspector_first_name} ${data.inspector_last_name}`;
    }
  }
}

const servicePath = "daily-logs";

const servicePlugin = makeServicePlugin({
  Model: DailyLog,
  debug: process.env.NODE_ENV !== "production",
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath,
  extend: () => {
    return {
      state: {
        pageSkip: 0,
        pageLimit: 50,
        sortField: null,
        isUpdatingPagination: false,
        isAddingFilter: false,
        isRemovingFilter: false,
        filterIdrDates: []
      },
      mutations: {
        setIsAddingFilter: (state) => {
          state.isAddingFilter = true;
        },
        unsetIsAddingFilter: (state) => {
          state.isAddingFilter = false;
        },
        setIsRemovingFilter: (state) => {
          state.isRemovingFilter = true;
        },
        unsetIsRemovingFilter: (state) => {
          state.isRemovingFilter = false;
        },
        setUpdatePaginationEnter: (state) => {
          state.isUpdatingPagination = true;
        },
        unsetUpdatePaginationEnter: (state) => {
          state.isUpdatingPagination = false;
        },
        setUpdatePagination: (state, payload) => {
          state.sortField = null;
          state.pageSkip = (payload.page - 1) * state.pageLimit;
          state.pageLimit = payload.itemsPerPage;
          state.sortField =
            payload.sortBy.length > 0 ? payload.sortBy[0] : null;
          state.sortDesc = payload.sortDesc[0] == true ? -1 : 1;
        },
        setFilterIdrDates: (state, payload) => {
          state.filterIdrDates = [];
          state.filterIdrDates = payload;
        }
      },
      actions: {
        updatePagination: (context, payload) => {
          context.commit("setUpdatePaginationEnter");
          context.commit("setUpdatePagination", payload);
          context.commit("unsetUpdatePaginationEnter");
        },
        filterIdrDates: (context, payload) => {
          context.commit("setIsAddingFilter");
          context.commit("setFilterIdrDates", payload);
          context.commit("unsetIsAddingFilter");
        }
      }
    };
  }
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [hideTestData()],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [
      // (context) => {
      //   let data = context.result;
      //   if (data.idr_photo !== null) {
      //     data.idr_photo = data.idr_photo.split(",");
      //   }
      //   return context;
      // }
    ],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
