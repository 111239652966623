// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import { store } from "@/store";

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";
import hideTestData from "../hooks/hide-test-data";

// Extend the base class
class Project extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Project";
  static idField = "fulcrum_id";
  static instanceDefaults() {
    return {
      fulcrum_id: null,
      form_id: null,
      status: null,
      project_fulcrum_id: null,
      assigned_to: null,
      assigned_to_id: null,
      version: null,
      client_created_at: null,
      created_at: null,
      created_by: null,
      created_by_id: null,
      created_duration: null,
      created_location: null,
      client_updated_at: null,
      updated_at: null,
      updated_by: null,
      updated_by_id: null,
      updated_duration: null,
      updated_location: null,
      edited_duration: null,
      old_project_id: null,
      permit_num_temp: null,
      hold_status: null,
      contract_record: null,
      proceed_num: null,
      proceed_name: null,
      proceed_desc: null,
      project_type: null,
      expedited: null,
      mot_req: null,
      fiscal_year: null,
      doc_link: null,
      gen_comment: null,
      assign_date: null,
      hand_off_date: null,
      calc_hand_off_ntp: null,
      hand_off_ntp_comment: null,
      calc_hand_off_start: null,
      hand_off_start_comment: null,
      proceed_date: null,
      estimate_start_date: null,
      rain_start_date: null,
      day_start_time: null,
      day_end_time: null,
      night_start_time: null,
      night_end_time: null,
      duration_days: null,
      estimate_end_date: null,
      calc_ntp_start: null,
      start_date: null,
      first_idr_date: null,
      last_idr_date: null,
      end_date: null,
      close_out_date: null,
      design_firm_record: null,
      designer_record: null,
      contractor_record: null,
      inspector_record: null,
      rejected: null,
      initial_amount: null,
      contingency: null,
      design_desc: null,
      contractor_list_record: null,
      short_list_record: null,
      reject_date: null,
      review_date: null,
      approve_date: null,
      distro_date: null,
      reject_comment: null,
      sub_end_date: null
    };
  }

  static setupInstance(data) {
    // handle null data fields
    // if (!data.designer_nm) {
    //   data.designer_nm = "No designer assigned";
    // }
    // if (!data.contractor_nm) {
    //   data.contractor_nm = "No contractor assigned";
    // }
    // if (!data.inspector_nm) {
    //   data.inspector_nm = "No inspector assigned";
    // }

    // remove the timestamp from the date
    if (data.assign_date) {
      data.assign_date = data.assign_date.slice(0, 10);
    }
    if (data.hand_off_date) {
      data.hand_off_date = data.hand_off_date.slice(0, 10);
    }
    if (data.proceed_date) {
      data.proceed_date = data.proceed_date.slice(0, 10);
    }
    if (data.start_date) {
      data.start_date = data.start_date.slice(0, 10);
    }
    if (data.rain_start_date) {
      data.rain_start_date = data.rain_start_date.slice(0, 10);
    }
    if (data.end_date) {
      data.end_date = data.end_date.slice(0, 10);
    }
    if (data.close_out_date) {
      data.close_out_date = data.close_out_date.slice(0, 10);
    }
    // remove the seconds from start/end time
    if (data.day_start_time) {
      data.day_start_time = data.day_start_time.slice(0, -3);
    }
    if (data.day_end_time) {
      data.day_end_time = data.day_end_time.slice(0, -3);
    }
    if (data.night_start_time) {
      data.night_start_time = data.night_start_time.slice(0, -3);
    }
    if (data.night_end_time) {
      data.night_end_time = data.night_end_time.slice(0, -3);
    }
  }
}

const servicePath = "projects";
const servicePlugin = makeServicePlugin({
  debug: process.env.NODE_ENV !== "production",
  Model: Project,
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath,
  extend: () => {
    return {
      state: {
        pageSkip: 0,
        pageLimit: 50,
        sortField: null,
        sortOrder: 1,
        isUpdatingPagination: false,
        isEnteringText: false,
        searchText: "",
        isAddingFilter: false,
        isRemovingFilter: false,
        activeFilters: [],
        filterText: null,
        filterContractors: [],
        filterDesignFirms: [],
        filterDesigners: [],
        filterFiscalYears: [],
        filterLeadInspectors: [],
        filterProjectTypes: [],
        filterProjectStatuses: [],
        loading: false
      },
      getters: {
        getLoading: (state) => {
          return state.loading;
        }
      },
      mutations: {
        setIsAddingFilter: (state) => {
          state.isAddingFilter = true;
        },
        unsetIsAddingFilter: (state) => {
          state.isAddingFilter = false;
        },
        setIsRemovingFilter: (state) => {
          state.isRemovingFilter = true;
        },
        unsetIsRemovingFilter: (state) => {
          state.isRemovingFilter = false;
        },
        setUpdatePaginationEnter: (state) => {
          state.isUpdatingPagination = true;
        },
        unsetUpdatePaginationEnter: (state) => {
          state.isUpdatingPagination = false;
        },
        setUpdatePagination: (state, payload) => {
          state.sortField = null;
          state.pageSkip = (payload.page - 1) * state.pageLimit;
          state.pageLimit = payload.itemsPerPage;
          state.sortField =
            payload.sortBy.length > 0 ? payload.sortBy[0] : null;
          state.sortDesc = payload.sortDesc[0] == true ? -1 : 1;
        },
        setFilterTextEnter: (state) => {
          state.isFilteringText = true;
        },
        unsetFilterTextEnter: (state) => {
          state.isFilteringText = false;
        },
        setFilterText: (state, payload) => {
          state.filterText = null;
          state.filterText = payload;
          console.log(state.filterText);
        },
        setFilterContractors: (state, payload) => {
          state.filterContractors = [];
          state.filterContractors = payload;
        },
        setFilterDesignFirms: (state, payload) => {
          state.filterDesignFirms = [];
          state.filterDesignFirms = payload;
        },
        setFilterDesigners: (state, payload) => {
          state.filterDesigners = [];
          state.filterDesigners = payload;
        },
        setFilterFiscalYears: (state, payload) => {
          state.filterFiscalYears = [];
          state.filterFiscalYears = payload;
        },
        setFilterLeadInspectors: (state, payload) => {
          state.filterLeadInspectors = [];
          state.filterLeadInspectors = payload;
        },
        setFilterProjectTypes: (state, payload) => {
          state.filterProjectTypes = [];
          state.filterProjectTypes = payload;
        },
        setFilterProjectStatuses: (state, payload) => {
          state.filterProjectStatuses = [];
          state.filterProjectStatuses = payload;
        },
        setClearAllFilters: (state) => {
          state.filterText = null;
          state.filterContractors = [];
          state.filterDesignFirms = [];
          state.filterDesigners = [];
          state.filterFiscalYears = [];
          state.filterLeadInspectors = [];
          state.filterProjectTypes = [];
          state.filterProjectStatuses = [];
        },
        setLoading: (state, payload) => {
          state.loading = payload;
        }
      },
      actions: {
        initializePagination: (context, payload) => {
          context.commit("setUpdatePaginationEnter");
          context.commit("setUpdatePagination", {
            page: 1,
            itemsPerPage: payload.initialItemsPerPage,
            sortBy: payload.initialSortBy || [],
            sortDesc: [false]
          });
          context.commit("unsetUpdatePaginationEnter");
        },
        updatePagination: (context, payload) => {
          context.commit("setUpdatePaginationEnter");
          context.commit("setUpdatePagination", payload);
          context.commit("unsetUpdatePaginationEnter");
        },
        filterText: (context, payload) => {
          context.commit("setFilterTextEnter");
          context.commit("setFilterText", payload);
          context.commit("unsetFilterTextEnter");
        },
        filterContractors: (context, payload) => {
          context.commit("setIsAddingFilter");
          context.commit("setFilterContractors", payload);
          context.commit("unsetIsAddingFilter");
        },
        filterDesignFirms: (context, payload) => {
          context.commit("setIsAddingFilter");
          context.commit("setFilterDesignFirms", payload);
          context.commit("unsetIsAddingFilter");
        },
        filterDesigners: (context, payload) => {
          context.commit("setIsAddingFilter");
          context.commit("setFilterDesigners", payload);
          context.commit("unsetIsAddingFilter");
        },
        filterFiscalYears: (context, payload) => {
          context.commit("setIsAddingFilter");
          context.commit("setFilterFiscalYears", payload);
          context.commit("unsetIsAddingFilter");
        },
        filterLeadInspectors: (context, payload) => {
          context.commit("setIsAddingFilter");
          context.commit("setFilterLeadInspectors", payload);
          context.commit("unsetIsAddingFilter");
        },
        filterProjectTypes: (context, payload) => {
          context.commit("setIsAddingFilter");
          context.commit("setFilterProjectTypes", payload);
          context.commit("unsetIsAddingFilter");
        },
        filterProjectStatuses: (context, payload) => {
          context.commit("setIsAddingFilter");
          context.commit("setFilterProjectStatuses", payload);
          context.commit("unsetIsAddingFilter");
        },
        clearAllFilters: (context) => {
          context.commit("setClearAllFilters");
        },
        updateLoading: (context, payload) => {
          context.commit("setLoading", payload);
        }
      },
      handleEvents: {
        created: (item) => {
          console.log("Greetings from the created event!");
          store.dispatch("projects/updateLoading", false);
          console.log(`${item} created in database`);
        },
        patched: (item) => {
          console.log("Greetings from the patched event!");
          store.dispatch("projects/updateLoading", false);
          console.log(`${item} created in database`);
        },
        updated: (item) => {
          console.log("Greetings from the updated event!");
          store.dispatch("projects/updateLoading", false);
          console.log(`${item} created in database`);
        },
        removed: (item) => {
          console.log("Greetings from the removed event!");
          store.dispatch("projects/updateLoading", false);
          console.log(`${item} created in database`);
        }
      }
    };
  }
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [hideTestData()],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
