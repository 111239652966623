import "@/plugins/vue-composition-api";
import "@/styles/styles.scss";
import "./vee-validate";

import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import VueLogger from "vue-logger-plugin";

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { store } from "./store";

Vue.use(VueLogger, {
  enabled: process.env.NODE_ENV !== "production",
  level: process.env.NODE_ENV === "production" ? "warn" : "debug",
  separator: "|",
  prefixFormat: ({ level }) => {
    const now = new Date();
    return `[${now.toLocaleTimeString()}] ${level.toUpperCase()} ${
      process.env.NODE_ENV
    }`;
  }
});
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
