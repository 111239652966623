// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";
// import { paramsForServer } from "feathers-hooks-common";

// Extend the base class
class NoticeToProceed extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "NoticeToProceed";
  static instanceDefaults() {
    return {
      fulcrum_id: null,
      proceed_num: null,
      contractor_name: null,
      contract_num: null,
      proceed_name: null,
      initial_amount: null,
      ntp_date: null,
      expedited: null,
      permit_num: null,
      duration_days: null,
      address: null,
      city_state: null,
      zip_code: null,
      contractor_poc_name: null,
      owner_name: null,
      estimate_end_date: null,
      designer_name: null,
      inspector_name: null,
      to_line_email: [],
      cc_line_email: [],
      bcc_line_email: [],
      cc_distro_list: [],
      signatory_name: null,
      signatory_title: null,
      missing_field: []
    };
  }
}

const servicePath = "notice-to-proceed";

const servicePlugin = makeServicePlugin({
  debug: process.env.NODE_ENV !== "production",
  Model: NoticeToProceed,
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
